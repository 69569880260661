<template>

  <Navigation />
  <HeroHeader />
  <main>
        <article>
          <section class="ui-step-by-step-box">
                <div class="container">
                    
                  <h1>E-Mail verification</h1>
                  <p v-if="valid">
                    Your E-Mail have been verified. You can close this page now
                  </p>
                  <p v-if="!valid">
                    Your Token is not valid. Please check you URL.
                  </p>
                </div>
                  </section>

        </article>
  </main>
  <Footer />
</template>

<script>
  import Navigation from '../components/Navigation.vue'
  import HeroHeader from '../components/HeroHeader.vue'
  // import MainArea from '../components/MainArea.vue'
  // // import TermsAndConditions from '../components/TermsAndConditions.vue'
  import Footer from '../components/Footer.vue'
  // import NewForm from '../components/NewForm.vue'
  // import { onMounted } from 'vue'


  export default {
    name: 'Verify',
    props:{
      token:{ required: true }
    },
    data(){
      return {
        valid : false
      }
    },
    mounted(){
      fetch(this.apiUrl+"api/customer/verify", {
        "method": "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        "body" : JSON.stringify({
          token : this.token
        })
      }).then(r => {
        if(r.status===200) this.valid = true;
        console.log(r);
      }).catch(e => console.log(e));
    },
 
    components: {
      HeroHeader,
      Navigation,
      // MainArea,
      // TermsAndConditions,
      Footer,
      // NewForm
    }
  }      
</script>

<style>

</style>
